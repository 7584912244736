
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import Users from "layouts/authentication/users";
import Tables from "layouts/tables";
import QuotationForm from "layouts/quotation";
import Customer from "layouts/customer";
import Item from "layouts/item";
import Employee from "layouts/employee";
import Jobs from "layouts/jobs";
import Expenses from "layouts/expenses";
import Personal from "layouts/personal";
import Project from"layouts/project";
import ProjectEngVice from "layouts/project/projectEngVice";
import Timeline from "layouts/timeline"

import Icon from "@mui/material/Icon";
  const routes = 
  [ 

  {
    type: "collapse",
    name:"Profile",
    key: "personal",
    icon: <Icon fontSize="small">personal</Icon>,
    route: "/personal" ,
    component: <Personal/> ,
  }
  ,  
  {
    type: "collapse",
    name: "Users",
    key: "Users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Users />,
  },
 
  {
    type: "collapse",
    name: "QuotationForm",
    key: "QuotationForm",
    icon: <Icon fontSize="small">request_quote</Icon>,
    route: "/quotation",
    component: <QuotationForm/>,
  },
  
  {
    type: "collapse",
    name: "Customer",
    key: "Customer",
    icon: <Icon fontSize="small">person4</Icon>,
    route: "/customer",
    component: <Customer/>,
  },
  {
    type: "collapse",
    name: "Item",
    key: "Item",
    icon: <Icon fontSize="small">featured_play_list</Icon>,
    route: "/item",
    component: <Item/>,
  },
  {
    type: "collapse",
    name: "Employee",
    key: "Employee",
    icon: <Icon fontSize="small">people_alt</Icon>,
    route: "/employee",
    component: <Employee/>,
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "Jobs",
    icon: <Icon fontSize="small">assignment_turned_in</Icon>,
    route: "/jobs",
    component: <Jobs/>,
  },
  {
    type: "collapse",
    name: "Expenses",
    key: "Expenses",
    icon: <Icon fontSize="small">walletIcon</Icon>,
    route: "/expenses",
    component: <Expenses/>,
  },
  {
    type: "collapse",   
    key: "Timeline",   
    route: "/timeline",
    component: <Timeline/>,
  },
  { 
    name:"tables",  
    key: "tables",
    route: "/tables",
    component: <Tables/>,
  },
  
  {
    name:"profile", 
    key: "profile",
    route: "/profile",
    component: <Profile />,
  },
   {
    name:"sign-in", 
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  
  {
    name:"sign-up", 
    key: "sign-up",
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
 
 
] ;

export default routes;
