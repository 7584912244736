import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DataGrid } from '@mui/x-data-grid';

const View = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({});
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const fetchCustomers = async () => {
    try {
      const dateString = data.date;
      const [day, month, year] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        const res = await axios.get(`${BASE_URL}/expenses/DateExpenses/${formattedDate}`);
        setRecords(res.data);
        setLoading(false);
      } else {
        console.error("Invalid date format:", dateString);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'expenses', headerName: 'Expenses' , width: 150 },
    { field: 'cost', headerName: 'Cost', width: 150 },
    { field: 'remarks', headerName: 'Remarks', width: 150 },
  ];

  const rows = records.map((output, index) => ({
    id: index + 1,
    cost: output.expenses,
    expenses: output.cost,
    remarks:output.remarks,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Details
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <br />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <div style={{ width: '100%' }}>
                <br />
                {error && <p>{error}</p>}
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                    }}
                    pageSizeOptions={[10, 20]}
                  />
                )}
              </div>
            </div>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default View;
