import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Divider, Typography, Grid, Checkbox, FormControlLabel } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const EditCustomer = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Mobile: '',
    Phone: '',
    Address: '',
    company: '0',
    idcustomer: '',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        company: data.company === 'Yes' ? '1' : '0',
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked ? '1' : '0',
    });
  };

  const validateForm = () => {
    const { Name, Email, Address, Mobile } = formData;
    if (!Name || !Email || !Address || !Mobile) {
      return 'Please fill out all required fields.';
    }
    return '';
  };

  const updateCustomer = async () => {
    const validationError = validateForm();
    if (validationError) {
      setFormError(validationError);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/customer/UpdateCustomer`, formData);
      console.log(response.data);
      onCustomerUpdated();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormError('');
  };

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Edit
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
          <h3>Edit Customer</h3>
            {formError && (
              <Typography color="error" variant="body2">
                {formError}
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="Name"
                  value={formData.Name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="Address"
                  value={formData.Address}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="Email"
                  type="email"
                  value={formData.Email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Phone"
                  name="Phone"
                  type="tel"
                  value={formData.Phone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Mobile"
                  name="Mobile"
                  type="tel"
                  value={formData.Mobile}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.company === '1'}
                      onChange={handleCheckboxChange}
                      name="company"
                    />
                  }
                  label="Company"
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton variant="contained" color="success" onClick={updateCustomer} fullWidth>
                  Update
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCustomer;
