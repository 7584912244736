import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Add from "./add";
import { BASE_URL } from "constants";
import View from './view';

export default function Expenses() {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCustomers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/expenses/AllExpenses`);
      setRecords(res.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'expenses', headerName: 'Expenses ($)', width: 150 },
    {
      field: 'View',
      headerName: '',
      renderCell: (cellValues) => (
        <View data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
      ),
      width: 150,
    },
  ];

  const rows = records.map((output, index) => ({
    id: index + 1,
    date:new Date(output.date).toLocaleDateString('en-GB', {  year: 'numeric', month: '2-digit',day: '2-digit' }).replace(/\//g, '-'),
    expenses: output.expenses,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br/>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <Add onCustomerAdded={fetchCustomers} />
        <div style={{ width: '100%' }}>
          <br/>
          {error && <p>{error}</p>}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 20]}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
