import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, MenuItem, Typography, Divider,
  Grid
} from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1); // Subtract one day
  return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
};

const CompleteJobs = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    job_hrs: '',
    hrs_for_day: '',
    job_status: '1',
    start_date: formatDate(new Date()),
    complete_date: formatDate(new Date()),
    hrs_to_complete: '',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/jobs/Jobsbyid/${data.quotation_no}`);
        setFormData({
          ...res.data[0],
          start_date: formatDate(new Date(res.data[0].start_date)),
          complete_date: formatDate(new Date(res.data[0].complete_date || new Date())),
        });
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    if (open) {
      fetchQuotationData();
    }
  }, [data.quotation_no, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addService = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/jobs/CompleteUpdate`, formData);
      console.log(response.data);
      onCustomerUpdated();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Complete
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Quotation"
                  name="quotation_no"
                  value={formData.quotation_no}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Total Hrs For Job"
                  name="job_hrs"
                  value={formData.job_hrs}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Working Hrs For Day"
                  name="hrs_for_day"
                  value={formData.hrs_for_day}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Start Date"
                  name="start_date"
                  type="date"
                  value={formData.start_date}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Hrs to Complete"
                  name="hrs_to_complete"
                  value={formData.hrs_to_complete}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Complete Date"
                  name="complete_date"
                  type="date"
                  value={formData.complete_date}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                  Complete
                </MDButton>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompleteJobs;
