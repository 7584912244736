import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Divider, Grid, MenuItem, FormControlLabel, Switch } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const EditItem = ({ data, onCustomerUpdated}) => {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    iditem: '',
    type_id: '',
    unit_id: '',
    isExpired: 0
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState([]);
  const [unit, setUnit] = useState([]);
  const [isService, setIsService] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        try {
          const res = await axios.get(`${BASE_URL}/item/Item/${data.iditem}`);
          setFormData(res.data[0]);
          const [typeResponse, unitResponse] = await Promise.all([
            axios.get(`${BASE_URL}/item/AllType`),
            axios.get(`${BASE_URL}/item/AllUnit`)
          ]);

          setType(typeResponse.data);
          setUnit(unitResponse.data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked ? 1 : 0,
    });
  };

  const updateCustomer = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/item/UpdatItem`, formData);
      console.log(response.data);
      onCustomerUpdated(); // Update the parent component
      handleClose(); // Close the dialog
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTypeChange = (e) => {
    setFormData({ ...formData, type_id: e.target.value });
  };

  const handleUnitChange = (e) => {
    setFormData({ ...formData, unit_id: e.target.value });
  };

  const handleChangeisExpire = (e) => {
    setFormData({ ...formData, isExpired: e.target.checked ? 1 : 0 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCustomer();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Edit
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Type"
                    name="type_id"
                    select
                    value={formData.type_id}
                    onChange={handleTypeChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Type</em>
                    </MenuItem>
                    {type.map((q) => (
                      <MenuItem key={q.id} value={q.id}>
                        {q.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
               
                <Grid item xs={6}>
                  <TextField
                    label="Unit"
                    name="unit_id"
                    select
                    value={formData.unit_id}
                    onChange={handleUnitChange}
                    fullWidth
                    margin="normal"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Unit</em>
                    </MenuItem>
                    {unit.map((q) => (
                      <MenuItem key={q.id} value={q.id}>
                        {q.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                      label="Price"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!formData.isExpired}
                          onChange={handleChangeisExpire}
                          name="isExpired"
                          color="primary"
                        />
                      }
                      label="Is Expired"
                    />
                  </Grid>
                              
                 
              
                <Grid item xs={12}>
                  <MDButton type="submit" variant="contained" color="success" fullWidth>
                    Update
                  </MDButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditItem;
