import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewItem from "./newItem";
import { BASE_URL } from "constants";
import EditItem from './editItem';

export default function Customer() {
  const [records, setRecord] = useState([]);

  const fetchCustomers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/item/AllServisAndItem`);
      setRecord(res.data);        
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);
  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };
  const columns = [
    { field: 'id', headerName: '', width: 50 },
    { field: 'iditem', headerName: 'Item ID', width: 80 },
    { field: 'Name', headerName: 'Name', width: 250 },
    { field: 'type_name', headerName: 'Type', width: 250 },
    { field: 'unit_name', headerName: 'Unit', width: 250 },
    { field: 'isExpired', headerName: 'Expired', width: 250 },
    { field: 'Price', headerName: 'Price', width: 150 },    
    { field: 'More', headerName: '', renderCell: (cellValues) => <EditItem data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />, width: 100},
  ];
    
  const rows = records.map((output, index) => ({
    id: index + 1,
    iditem:output.iditem,
    Name: output.name,
    Price: output.price,
    type_name:output.type_name,
    unit_name:output.unit_name,
    isExpired:(output.isExpired == 1) ? 'Yes' : '',
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br/>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <NewItem onCustomerAdded={fetchCustomers} />
        <div style={{ width: '100%' }}>
          <br/>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
        
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
