import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, Divider, Grid, MenuItem } from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const NewJobs = ({ onCustomerAdded }) => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    job_hrs: '',
    hrs_for_day: '',
    job_status: '1',
    start_date: '',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [quotation, setQuotation] = useState([]);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add one day
    return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dtypeRes = await axios.get(`${BASE_URL}/jobs/AllQuotation`);
        setQuotation(dtypeRes.data.map(item => ({
          quotation_no: item.quotation_no,
          name: item.name,
        })));

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleQuotationChange = (e) => {
    const quotation_no = e.target.value;
    const selected = quotation.find(q => q.quotation_no === quotation_no);

    setFormData({
      ...formData,
      quotation_no: quotation_no,
    });

    setSelectedQuotation(selected);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addService = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/jobs/Save`, formData);
      console.log(response.data);
      onCustomerAdded();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        New Job
      </MDButton>

      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Quotation"
                  name="Quotation"
                  select
                  value={formData.quotation_no}
                  onChange={handleQuotationChange}
                  fullWidth
                  margin="normal"
                  required
                >
                  <MenuItem value="">
                    <em>Select Quotation</em>
                  </MenuItem>
                  {quotation.map((q) => (
                    <MenuItem key={q.quotation_no} value={q.quotation_no}>
                      {q.quotation_no} {q.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {selectedQuotation && (
                  <Typography variant="h6">
                    {selectedQuotation.quotation_no} - {selectedQuotation.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Total Hrs For Job"
                  name="job_hrs"
                  value={formData.job_hrs}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Working Hrs For Day"
                  name="hrs_for_day"
                  value={formData.hrs_for_day}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Date"
                  name="start_date"
                  type="date"
                  value={formData.start_date}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton variant="contained" color="success" onClick={addService} fullWidth>
                  Add
                </MDButton>
              </Grid>
            </Grid>
            <Divider />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewJobs;
