import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewJobs from './newJobs';
import { BASE_URL } from "constants";
import EditJobs from './editJobs';
import CompleteJobs from './completeJobs';
import JobEmpRegistration from './jobEmpRegistration';

import { Button} from '@mui/material';
export default function Jobs() {
  const [records, setRecord] = useState([]);

  const fetchCustomers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/jobs/Alljobs`);
      setRecord(res.data);        
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);
  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };
  const columns = [
    { field: 'id', headerName: '', width: 50 },
    { field: 'idjobs', headerName: 'Job ID', width: 80 },
    { field: 'quotation_no', headerName: 'Quotation No', width: 120 },
    { field: 'name', headerName: 'Name', width: 200 },       
    { field: 'job_hrs', headerName: 'Job Hrs', width: 100},       
    { field: 'hrs_for_day', headerName: 'Hrs for Day', width: 100 },    
    { field: 'job_status', headerName: 'Status',  width: 120},  
    { field: 'date', headerName: 'Date', width: 125 },   
    { field: 'cdate', headerName: 'Complete Date',  width: 125},  
    { field: 'hrs_to_complete', headerName: 'Hrs for Complete', width: 150 },   
    { field: 'Edit',
      headerName: '',
      renderCell: (cellValues) => {
        return cellValues.row.job_status === 'Complete' ? (
          <Button disabled>Edit</Button>
        ) : (
          <EditJobs data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
        );
      },
      width: 100,
    },
    { field: 'Emp',
      headerName: '',
      renderCell: (cellValues) => {
        return cellValues.row.job_status === 'Complete' ? (
          <Button disabled>Employee</Button>
        ) : (
          <JobEmpRegistration data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
        );
      },
  width: 100,
},   
    { field: 'Status', headerName: '', 
      renderCell: (cellValues) => <CompleteJobs data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
      , width: 120},
   
   
  ];
    
  const rows = records.map((output, index) => ({
    id: index + 1,
    idjobs:output.idjobs,
    quotation_no: output.quotation_no,
    name: output.name,
    job_hrs: output.job_hrs,
    hrs_for_day: output.hrs_for_day,
    job_status: output.Sname,
    date: new Date(output.start_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  cdate: output.complete_date ? new Date(output.complete_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '',
    hrs_to_complete: output.hrs_to_complete,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br/>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <NewJobs onCustomerAdded={fetchCustomers} />
        <div style={{ width: '100%' }}>
          <br/>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
        
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
