
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProjectEng from "layouts/project/projectEng";
// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import QuotationForm from "layouts/quotation";
// Dashboard components
import OrdersOverview from "layouts/personal/components/OrdersOverview";
import ProjectDash from "layouts/project/projectDash";
import MonthlyEmp from "layouts/timeline/monthlyEmp";
import MonthlyJobs from "layouts/timeline/monthlyJobs";
import Calendar from "layouts/calendar/Calendar";
function Personal() {
  const { sales, tasks } = reportsLineChartData;
  
  return (
    <DashboardLayout>
      <DashboardNavbar />   
      {/* <MonthlyJobs/>
      <br/>
      <MonthlyEmp/> */}
       {/* <ProjectDash/>
       <br/>
       <br/>
<ProjectEng/> */}
<br/>
<Calendar/>
    </DashboardLayout>
  );
}
export default Personal;
