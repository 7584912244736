import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, MenuItem, Typography, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid
} from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ViewQuotation = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    customer_id: '',
    status: '',
    status_date: new Date().toISOString().split('T')[0],
    net_price: '',
    tax: '',
    total: '',
    tax_rate: 0,
    is_tax: '',
    remarks: '',
    date:'',
  });

  const [tax, setTax] = useState({
    name: '',
    tax_rate: ''
  });
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [newService, setNewService] = useState({ id: '', quantity: 1 });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [registeredCustomers, setRegisteredCustomers] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/quotation/QuotationNo/${data.quotation_no}`);
        setFormData(res.data[0]);
        const resItems = await axios.get(`${BASE_URL}/quotation/QuotationNoItem/${data.quotation_no}`);
        setSelectedServices(resItems.data.map(item => ({
          id: item.item_id,
          name: item.item,
          quantity: item.qty,
          price: item.unit_price,
          total_price: item.total_price,
        })));
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    fetchQuotationData();
  }, [data.quotation_no]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemRes = await axios.get(`${BASE_URL}/item/AllItem`);
        setServices(itemRes.data.map(item => ({
          id: item.iditem,
          name: item.name,
          price: item.price,
        })));
        const taxRes = await axios.get(`${BASE_URL}/quotation/tax`);
        setTax(taxRes.data);
        const customerRes = await axios.get(`${BASE_URL}/customer/Allcustomers`);
        setRegisteredCustomers(customerRes.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setNewService({
      ...newService,
      [name]: value,
    });
  };

 

  

 

  const handleSubmit = (e) => {
    e.preventDefault();
    const quotation = {
      ...formData,
      services: selectedServices,
    };
    generatePDF(quotation);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1); // Subtract one day
    return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
  };
  const generatePDF = (quotation) => {
    const doc = new jsPDF();
  
    // Company Details
    doc.setFontSize(18);
    doc.text('ENRO IMPRESA DI PULIZIA', 14, 15);
    doc.setFontSize(11);
    doc.text('Via Sant Antonio,', 14, 29);
    doc.text('327 40059 Medicina (BO)', 14, 36);
    doc.text('Phone: +393295525997', 14, 43);
    doc.text('Email: info@enroservice.com', 14, 50);

    // Quotation Title
    doc.setFontSize(20);
    doc.text('Quotation', 170, 15);

    // Quotation Info
    doc.setFontSize(11);
    doc.text(`DATE: ${formatDate(quotation.date)}`, 150, 30); // Format date function
    doc.text(`Quotation #: ${quotation.quotation_no}`, 150, 37);
    doc.text(`Customer ID: ${quotation.customer_id}`, 150, 44);

    // Bill To
    doc.setFontSize(11);
    doc.text('Bill To:', 14, 60);
    doc.text(quotation.name, 14, 67);
    doc.text(quotation.address, 14, 74);

    // Comments or Special Instructions
    doc.text(quotation.remarks || '', 14, 84);

    // Service Table
    const tableColumn = ['Description', 'Quantity', 'Price per Unit', 'Total Price'];
    const tableRows = [];

    (quotation.services || []).forEach((service) => {
      const serviceData = [
        service.name,
        service.quantity,
        `€ ${service.price}`,
        `€ ${service.price * service.quantity}`,
      ];
      tableRows.push(serviceData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 94,  // Updated startY to place it after remarks
      styles: { halign: 'center' },
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
    });

    // Total
    const totalY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(11);
    doc.text(`Net: € ${quotation.net_price}`, 170, totalY);
    doc.text(`Tax: € ${quotation.tax*100}`, 170, totalY + 7); // Adjusted for tax
    doc.text(`Total: € ${quotation.total}`, 170, totalY + 14); // Adjusted for total

    // Footer
    doc.setFontSize(11);
    doc.text('This quotation is valid for 14 days from the issue date.', 14, totalY + 30);
    doc.setFontSize(13);
    doc.text('THANK YOU FOR YOUR BUSINESS!', 14, totalY + 40);

    doc.save('quotation.pdf');
  };
  
  const calculateTotal = (services = []) => {
    return services.reduce((total, service) => total + (service.price * service.quantity), 0).toFixed(2);
  };

  const calculateTax = (total) => {
    return (total * formData.tax).toFixed(2);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        View/Print
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <form onSubmit={handleSubmit}>
              <Typography variant="h4" gutterBottom>
                Quotation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Quotation No"
                    name="quotation_no"
                    value={formData.quotation_no}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Address"
                    name="address"
                    value={formData.address || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Date"
                    name="date"
                    type="date"
                    value={(() => {
                      let date = new Date(formData.date);
                      date.setDate(date.getDate() - 1); // Subtract one day
                      return date.toISOString().split('T')[0];
                    })()}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Selected Services
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead></TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ width: '30%' }}>Service</TableCell>
                          <TableCell style={{ width: '15%' }} align="right">Quantity</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Price per Unit</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Total Price</TableCell>
                          <TableCell style={{ width: '15%' }} align="right"></TableCell>
                        </TableRow>
                        {selectedServices.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ width: '30%' }} component="th" scope="row">
                              {service.name}
                            </TableCell>
                            <TableCell style={{ width: '15%' }} align="right">{service.quantity}</TableCell>
                            <TableCell style={{ width: '20%' }} align="right">€ {service.price}</TableCell>
                            <TableCell style={{ width: '20%' }} align="right">€ {service.total_price}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={2}>Net</TableCell>
                          <TableCell align="right">€ {calculateTotal(selectedServices)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>{tax[0].name} {(formData.tax) * 100}%</TableCell>
                          <TableCell align="right">€ {calculateTax(calculateTotal(selectedServices))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Total</TableCell>
                          <TableCell align="right">€ {(parseFloat(calculateTotal(selectedServices)) + parseFloat(calculateTax(calculateTotal(selectedServices)))).toFixed(2)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <MDButton type="submit" variant="contained" color="success" onClick={handleSubmit} fullWidth>
                    Print Quotation
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewQuotation;
