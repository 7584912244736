import React, { useEffect, useRef, useState } from 'react';
import { DayPilot, DayPilotCalendar, DayPilotMonth, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import axios from 'axios';
import { BASE_URL } from "constants";
import "./Calendar.css";

const Calendar = () => {
  const monthRef = useRef();
  const [view, setView] = useState("Week");
  const [startDate, setStartDate] = useState(DayPilot.Date.today());
  const [events, setEvents] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/jobs/Alljobs`);
      const records = res.data;
      const rese = await axios.get(`${BASE_URL}/jobs/AllJobsWithEmp`);
      const erecords = rese.data;

      // Create events based on the fetched data
      const events = records.map((record, index) => {
        const isComplete = record.Sname === 'Complete';
        const startDate = new Date(record.start_date);
        const endDate = isComplete
          ? new Date(record.complete_date)
          : new Date(startDate.getTime() + (record.job_hrs / record.hrs_for_day) * 24 * 60 * 60 * 1000);

        // Adjust for proper date formatting
        const startDateString = new Date(startDate.setDate(startDate.getDate() + 1)).toISOString().split('T')[0];
        const endDateString = new Date(endDate.setDate(endDate.getDate() + 1)).toISOString().split('T')[0];

        // Find the corresponding employee record
        const empRecord = erecords.filter(emp => emp.quotation_no === record.quotation_no);
        const empName = empRecord.length > 0 
        ? empRecord.map(emp => emp.name).join(', ') 
        : 'Unknown';

        return {
          id: index + 1,
          text: `${record.quotation_no} Cus: ${record.name} Emp: ${empName}`,
          start: startDateString,
          end: endDateString,
          borderColor: isComplete ? "#23abfa" : "#b2d964",
          backColor: isComplete ? "#23abfa" : "#b2d964"
        };
      });

      setEvents(events);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onTimeRangeSelected = async (args) => {
    // const dp = args.control;
    // const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
    // dp.clearSelection();
    // if (modal.canceled) {
    //   return;
    // }
    // const e = {
    //   start: args.start,
    //   end: args.end,
    //   text: modal.result
    // };
   // setEvents([...events, e]);
  };

  return (
    <div className={"container"}>
     
      
      <div className={"navigator"}>
        <DayPilotNavigator
          selectMode={view}
          showMonths={3}
          skipMonths={3}
          onTimeRangeSelected={args => setStartDate(args.day)}
          events={events}
        />
      </div>
      <div className={"content"}>
        <div className={"toolbar"}>
          <div className={"toolbar-group"}>
            <button onClick={() => setView("Day")} className={view === "Day" ? "selected" : ""}>Day</button>
            <button onClick={() => setView("Week")} className={view === "Week" ? "selected" : ""}>Week</button>
            <button onClick={() => setView("Month")} className={view === "Month" ? "selected" : ""}>Month</button>
          </div>
          <button onClick={() => setStartDate(DayPilot.Date.today())} className={"standalone"}>Today</button>
        </div>

        <DayPilotCalendar
          viewType={"Day"}
          startDate={startDate}
          events={events}
          visible={view === "Day"}
          durationBarVisible={false}
          onTimeRangeSelected={onTimeRangeSelected}
        />
        <DayPilotCalendar
          viewType={"Week"}
          startDate={startDate}
          events={events}
          visible={view === "Week"}
          durationBarVisible={false}
          onTimeRangeSelected={onTimeRangeSelected}
        />
        <DayPilotMonth
          startDate={startDate}
          events={events}
          visible={view === "Month"}
          eventBarVisible={false}
          onTimeRangeSelected={onTimeRangeSelected}
          ref={monthRef}
        />
      </div>
    </div>
  );
};

export default Calendar;
