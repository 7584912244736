import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewQuotation from "./newQuotation";
import { BASE_URL } from "constants";
import EditQuotation from './editQuotaion';
import ViewQuotation from './viewQuotaion';
import StatusQuotation from './statusQuotaion';
import { Button, Tooltip } from '@mui/material';
import './Quotation.css'; // Import the CSS file

export default function Quotation() {
  const [records, setRecord] = useState([]);
  const [error, setError] = useState(null);

  const fetchCustomers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/quotation/AllQuotation`);
      setRecord(res.data);        
      setError(null); // Reset error on success
    } catch (err) {
      console.log(err);
      setError('Failed to load quotations. Please try again.');
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };

  const rows = useMemo(() => 
    records.map((output, index) => ({
      id: index + 1,
      quotation_no: output.quotation_no,
      Name: output.name,
      Status: output.Status,
      StatusDate: new Date(output.status_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      total: output.total,
      is_tax: output.is_tax,
      remarks: output.remarks,
    })), [records]
  );

  const columns = [
    { field: 'id', headerName: '', width: 50 },
    { field: 'quotation_no', headerName: 'Quotation No', width: 110 },
    { field: 'Name', headerName: 'Customer', width: 250 },
    { field: 'Status', headerName: 'Status', width: 150 },
    { field: 'StatusDate', headerName: 'Status Date', width: 150 },
    { field: 'total', headerName: 'Total', width: 150 },
    { field: 'remarks', headerName: 'Remarks', width: 150 },
    {
      field: 'Edit',
      headerName: '',
      renderCell: (cellValues) => {
        return cellValues.row.Status === 'Approved' ? (
          <Tooltip title="Editing disabled for approved quotations">
            <span>
              <Button disabled>Edit</Button>
            </span>
          </Tooltip>
        ) : (
          <EditQuotation data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
        );
      },
      width: 100,
    },
    {
      field: 'View',
      headerName: '',
      renderCell: (cellValues) => (
        <ViewQuotation data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
      ),
      width: 150,
    },
    {
      field: 'StatusA',
      headerName: '',
      renderCell: (cellValues) => (
        <StatusQuotation data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />
      ),
      width: 150,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br/>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <NewQuotation onCustomerAdded={fetchCustomers} />
        <div style={{ width: '100%' }}>
          <br/>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowClassName={(params) => {
              if (params.row.Status === 'Rejected') return 'rejected-row';
              if (params.row.Status === 'Approved') return 'approved-row';
              return '';
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
