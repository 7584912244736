import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Button, MenuItem, Typography, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Switch, FormControlLabel
} from '@mui/material';
import MDButton from "components/MDButton";
import { BASE_URL } from "constants";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const StatusQuotation = ({ data, onCustomerUpdated }) => {
  const [formData, setFormData] = useState({
    quotation_no: '',
    customer_id: '',
    status: '',
    status_date: new Date().toISOString().split('T')[0],
    net_price: '',
    tax: '',
    total: '',
    tax_rate: 0,
    is_tax: false,
    remarks: '',
    date: '',
  });

  const [tax, setTax] = useState({
    name: '',
    tax_rate: ''
  });
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [registeredCustomers, setRegisteredCustomers] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuotationData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/quotation/QuotationNo/${data.quotation_no}`);
        setFormData(res.data[0]);
        const resItems = await axios.get(`${BASE_URL}/quotation/QuotationNoItem/${data.quotation_no}`);
        setSelectedServices(resItems.data.map(item => ({
          id: item.item_id,
          name: item.item,
          quantity: item.qty,
          price: item.unit_price,
          total_price: item.total_price,
        })));
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    fetchQuotationData();
  }, [data.quotation_no]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemRes = await axios.get(`${BASE_URL}/item/AllItem`);
        setServices(itemRes.data.map(item => ({
          id: item.iditem,
          name: item.name,
          price: item.price,
        })));
        const taxRes = await axios.get(`${BASE_URL}/quotation/tax`);
        setTax(taxRes.data);
        const customerRes = await axios.get(`${BASE_URL}/customer/Allcustomers`);
        setRegisteredCustomers(customerRes.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeTax = (event) => {
    const { checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      is_tax: checked ? 1 : 0,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitStatus = async (status) => {
    try {
      const responseHeader = await axios.post(`${BASE_URL}/quotation/updateStatus`, {
        quotation_no: formData.quotation_no,
        status,
        status_date: formData.status_date,
        is_tax: formData.is_tax,
      });

      onCustomerUpdated();
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1); // Subtract one day
    return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
  };

  const calculateTotal = (services = []) => {
    return services.reduce((total, service) => total + (service.price * service.quantity), 0).toFixed(2);
  };

  const calculateTax = (total) => {
    return (total * formData.tax).toFixed(2);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <>
      <MDButton type="button" variant="gradient" color="success" size="large" onClick={handleClickOpen}>
        Status
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogContent>
          <Container maxWidth="sm">
            <form>
              <Typography variant="h4" gutterBottom>
                Quotation
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Quotation No"
                    name="quotation_no"
                    value={formData.quotation_no}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Address"
                    name="address"
                    value={formData.address || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Date"
                    name="date"
                    type="date"
                    value={formatDate(formData.date)}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Selected Services
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead></TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ width: '30%' }}>Service</TableCell>
                          <TableCell style={{ width: '15%' }} align="right">Quantity</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Price per Unit</TableCell>
                          <TableCell style={{ width: '20%' }} align="right">Total Price</TableCell>
                          <TableCell style={{ width: '15%' }} align="right"></TableCell>
                        </TableRow>
                        {selectedServices.map((service, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ width: '30%' }} component="th" scope="row">
                              {service.name}
                            </TableCell>
                            <TableCell style={{ width: '15%' }} align="right">{service.quantity}</TableCell>
                            <TableCell style={{ width: '20%' }} align="right">€ {service.price}</TableCell>
                            <TableCell style={{ width: '20%' }} align="right">€ {service.total_price}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell rowSpan={3} />
                          <TableCell colSpan={2}>Net</TableCell>
                          <TableCell align="right">€ {calculateTotal(selectedServices)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>{tax[0].name} {(formData.tax) * 100}%</TableCell>                          
                          <TableCell align="right">€ {calculateTax(calculateTotal(selectedServices))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Total</TableCell>
                          <TableCell align="right">€ {(parseFloat(calculateTotal(selectedServices)) + parseFloat(calculateTax(calculateTotal(selectedServices)))).toFixed(2)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!formData.is_tax}
                        onChange={handleChangeTax}
                        name="is_tax"
                        color="primary"
                      />
                    }
                    label="Include Tax"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="contained" color="success" onClick={() => handleSubmitStatus(2)} fullWidth>
                    Approved
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton variant="contained" color="error" onClick={() => handleSubmitStatus(3)} fullWidth>
                    Rejected
                  </MDButton>
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatusQuotation;
