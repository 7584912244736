import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewCustomer from "./newCustomer";
import { BASE_URL } from "constants";
import EditCustomer from './editCustomer';

export default function Customer() {
  const [records, setRecord] = useState([]);

  const fetchCustomers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/customer/Allcustomers`);
      setRecord(res.data);        
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);
  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };
  const columns = [
    { field: 'id', headerName: '', width: 50 },
    { field: 'idcustomer', headerName: 'Cus ID', width: 80 },
    { field: 'Name', headerName: 'Name', width: 250 },
    { field: 'Mobile', headerName: 'Mobile', width: 150 },       
    { field: 'Phone', headerName: 'Phone', width: 150 },       
    { field: 'Address', headerName: 'Address', width: 300 },   
    { field: 'Email', headerName: 'Email',  width: 200},
    { field: 'company', headerName: 'Company',  width: 80},
    { field: 'More', headerName: '', renderCell: (cellValues) => <EditCustomer data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />, width: 100},
  ];
    
  const rows = records.map((output, index) => ({
    id: index + 1,
    idcustomer:output.idcustomer,
    Name: output.name,
    Mobile: output.tp1,
    Phone: output.tp2,
    Address: output.address,
    Email: output.email,
    company: (output.is_company == 1) ? 'Yes' : '',

  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br/>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <NewCustomer onCustomerAdded={fetchCustomers} />
        <div style={{ width: '100%' }}>
          <br/>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
        
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
