import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "constants";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Scheduler from "./Scheduler";
import SchedulerTime from "./SchedulerTime";
import MonthlyJobs from "./monthlyJobs";
import MonthlyEMP from "./monthlyEmp";


export default function TimeLine() {
  const [value, setValue] = useState('1');
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchStock = useCallback(async () => {
    try {
      const res = await axios.get(`${BASE_URL}/Employee/AllEmployee`);
      setRecords(res.data);
      setLoading(false);
      if (res.data.length > 0) {
        setValue(res.data[0].CurrentStatus);
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStock();
  }, [fetchStock]);

  const handleSave = () => {
    fetchStock();
    setRefresh(prev => !prev); // Toggle refresh state to trigger Scheduler update
  };

  return (
    <DashboardLayout>
      <br/>
    <h4>Jobs</h4>
      <MonthlyJobs refresh={refresh} /> 
      <br/>
      <br/>
      <h4>Employee</h4>     
     <MonthlyEMP refresh={refresh}/>
    </DashboardLayout>
  );
}
