import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewEmployee from './newEmployee';
import { BASE_URL } from "constants";
import EditEmployee from './editEmployee';
import EmpCalendar from './empCalendar';
export default function Employee() {
  const [records, setRecord] = useState([]);

  const fetchCustomers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/Employee/AllEmployee`);
      setRecord(res.data);        
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);
  const handleCustomerUpdated = () => {
    fetchCustomers(); // Fetch the updated list of customers
  };
  const columns = [
    { field: 'id', headerName: '', width: 50 },
    { field: 'idEmp', headerName: 'Emp ID', width: 80 },
    { field: 'Name', headerName: 'Name', width: 250 },
    { field: 'Mobile', headerName: 'Mobile', width: 150 },       
    { field: 'Phone', headerName: 'Phone', width: 150 },       
    { field: 'Address', headerName: 'Address', width: 300 },   
    { field: 'Email', headerName: 'Email',  width: 200},  
   
    { field: 'Designation', headerName: 'Designation', width: 300 },   
    { field: 'Type', headerName: 'Type',  width: 200}, 
    { field: 'Calendar', headerName: '', renderCell: (cellValues) => <EmpCalendar data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />, width: 100},
    { field: 'More', headerName: '', renderCell: (cellValues) => <EditEmployee data={cellValues.row} onCustomerUpdated={handleCustomerUpdated} />, width: 100},
  ];
    
  const rows = records.map((output, index) => ({
    id: index + 1,
    idEmp:output.idemployee,
    Name: output.name,
    Mobile: output.tp1,
    Phone: output.tp2,
    Address: output.address,
    Email: output.email,
    Passport: output.passport,
    NIC: output.nic,
    Designation: output.desName,
    Type: output.typName,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br/>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <NewEmployee onCustomerAdded={fetchCustomers} />
        <div style={{ width: '100%' }}>
          <br/>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
        
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
