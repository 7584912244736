import React, { useEffect, useRef, useState } from 'react';
import { DayPilot, DayPilotMonth } from "@daypilot/daypilot-lite-react";
import axios from 'axios';
import { BASE_URL } from "constants";

const MonthlyEmp = () => {
  const monthRef = useRef();
  const [records, setRecords] = useState([]);
  const [config, setConfig] = useState({
    locale: "en-us",
    timeRangeSelectedHandling: "Enabled",
    onTimeRangeSelected: async (args) => {
      const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
      const dp = args.control;
      dp.clearSelection();
      if (modal.canceled) { return; }
      dp.events.add({
        start: args.start,
        end: args.end,
        id: DayPilot.guid(),
        text: modal.result
      });
    },
    eventDeleteHandling: "Disabled",
    eventMoveHandling: "Disabled",
    eventResizeHandling: "Disabled",
    eventClickHandling: "Select",
    onEventSelected: (args) => {
      args.control.message("Event selected: " + args.e.text());
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/jobs/AllJobsWithEmp`);
        setRecords(res.data);

        // Create events based on the fetched data
        const events = res.data.map((record, index) => {
          const isComplete = record.job_status === '2';

          // Parse start and end dates and add one day to each
          const start = new Date(record.strat_date_time);
          start.setDate(start.getDate() + 1);
          const end = new Date(record.end_date_time);
          end.setDate(end.getDate() + 1);

          return {
            id: index + 1,
            text:  `${record.quotation_no} Emp: ${record.name}`,
            start: start.toISOString(),
            end: end.toISOString(),
            borderColor: isComplete ? "#23abfa" : "#b2d964",
            backColor: isComplete ? "#23abfa" : "#b2d964"
          };
        });

        // Update config with events
        setConfig(prevConfig => ({
          ...prevConfig,
          events
        }));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const getMonth = () => monthRef.current?.control;

  return (
    <>
      <h4>Employee</h4>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <h4 style={{ marginRight: '10px', color: '#23abfa' }}>Complete</h4>
        <div style={{ width: '20px', height: '20px', backgroundColor: '#23abfa', marginRight: '20px' }}></div>
        <h4 style={{ marginRight: '10px', color: '#b2d964' }}>Open</h4>
        <div style={{ width: '20px', height: '20px', backgroundColor: '#b2d964' }}></div>
      </div>
      <div>
        <DayPilotMonth
          {...config}
          ref={monthRef}
        />
      </div>
    </>
  );
};

export default MonthlyEmp;
